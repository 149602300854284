import { useMemo } from 'react'

import { useCreditCardForm } from '~/contexts/creditCardForm'
import { usePaymentMethod } from '~/contexts/paymentMethod'

import { PrimaryButton } from '~/components/common/Button/PrimaryButton'
import { SecondaryButton } from '~/components/common/Button/SecondaryButton'
import { Drawer } from '~/components/common/Drawer'
import { DrawerBody } from '~/components/common/Drawer/DrawerBody'
import { DrawerFooter } from '~/components/common/Drawer/DrawerFooter'
import { DrawerHeader } from '~/components/common/Drawer/DrawerHeader'
import { SaveIcon } from '~/components/common/icons/SaveIcon'
import { Body } from './Body'

interface IChangePaymentMethodDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const ChangePaymentMethodDrawer: React.FC<IChangePaymentMethodDrawerProps> = ({ isOpen, onClose }) => {
  const { isSubmitting, handleSubmitForm } = useCreditCardForm()
  const { paymentMethod } = usePaymentMethod()

  const buttonText = useMemo(() => (paymentMethod === 'credit_card' ? 'Salvar e pagar' : 'Salvar'), [paymentMethod])
  const onClick = useMemo(
    () => (paymentMethod === 'credit_card' ? handleSubmitForm : onClose),
    [paymentMethod, handleSubmitForm, onClose]
  )

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader title="Alterar forma de pagamento" />
      <DrawerBody>
        <Body />
      </DrawerBody>
      <DrawerFooter>
        <SecondaryButton className="max-w-[273px]" onClick={onClose}>
          Cancelar
        </SecondaryButton>
        <PrimaryButton isLoading={isSubmitting} className="max-w-[273px]" onClick={onClick}>
          <SaveIcon />
          {buttonText}
        </PrimaryButton>
      </DrawerFooter>
    </Drawer>
  )
}
