import { useCallback } from 'react'

import clsx from 'clsx'

import { DrawerCloseButton } from './DrawerCloseButton'

export const Drawer: React.FC<IDrawerProps> = ({ children, isOpen, onClose, title }) => {
  const backgroundClass = clsx(
    'z-[900] min-h-screen w-screen flex md:hidden lg:hidden fixed overflow-hidden bg-black/70 bg-opacity-25 inset-0 transform ease-in-out transition-opacity opacity-100 duration-500 translate-x-0 animate-modal-show'
  )
  const wrapperClass = clsx(
    'w-screen max-w-3xl right-0 absolute bg-primary-100 h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform ease-in-out translate-x-0 animate-modal-show'
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  if (!isOpen) return <></>

  return (
    <div className={backgroundClass} role="button" tabIndex={0} onKeyDown={handleKeyDown}>
      <div className={wrapperClass}>
        <div className="relative flex w-full items-center justify-between p-4">
          <DrawerCloseButton onClose={onClose} />
          <p className="mx-auto font-bold text-base">{title}</p>
        </div>
        <div className="relative max-w-3xl flex flex-col overflow-auto w-[90%] h-[90%] bg-primary-100 mx-auto custom-scrollbar">
          {children}
        </div>
      </div>
    </div>
  )
}
