import { CheckIcon } from '~/components/common/icons/CheckIcon'

interface IInstruction {
  children?: React.ReactNode
  text?: string
}

export const Instruction: React.FC<IInstruction> = ({ children, text }) => {
  return (
    <div className="flex gap-2 items-center">
      <div>
        <CheckIcon />
      </div>
      {text && <p className="text-default">{text}</p>}
      {children}
    </div>
  )
}
