import clsx from 'clsx'

import { Image } from '~/components/common/Image'

const dropwdownItemBase = 'p-[12px] cursor-pointer text-xs flex min-w-max'
const hoverFirstItem = 'hover:bg-primary-50 hover:rounded-t-lg'
const hoverItem = 'hover:bg-primary-50'
const hoverLastItem = 'hover:bg-primary-50 hover:rounded-b-lg'

export const DropdownItem: React.FC<TDropdownMenuProperties> = ({
  children,
  svgIcon,
  index,
  itemsLength,
  className
}) => {
  const dropdownItemClass = clsx(
    {
      [dropwdownItemBase]: true,
      [hoverFirstItem]: index === 0,
      [hoverItem]: index && itemsLength && index > 0 && index < itemsLength,
      [hoverLastItem]: index && itemsLength && index === itemsLength - 1
    },
    className
  )
  return (
    <li className={dropdownItemClass}>
      {svgIcon && (
        <div className="mr-2 flex justify-center items-center">
          <div className="w-3 h-3">
            <Image src={svgIcon} alt="action-menu" />
          </div>
        </div>
      )}
      {children}
    </li>
  )
}
