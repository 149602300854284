import { useCallback, useEffect, useMemo, useState } from 'react'

import type { ActionArgs, ErrorBoundaryComponent, LoaderArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import invariant from 'tiny-invariant'
import logo from 'public/assets/logo_full-color.webp'
import { logoDimensions } from '~/constants/imageSizes'

import { bgDimensions } from '~/constants/imageSizes'

import { generateRecurrenceToken, getCheckoutApi, getRecurrenceData } from '~/services/checkout'
import { payWithCreditCard, saveCreditCard } from '~/services/checkout/creditCard'
import { generatePix } from '~/services/checkout/pix'
import { generateTicket } from '~/services/checkout/ticket'
import { getPagarMeApi } from '~/services/pagarme'

import { useCreditCardForm } from '~/contexts/creditCardForm'
import { usePaymentMethod } from '~/contexts/paymentMethod'

import { Divider } from '~/components/common/Divider'
import { ErrorBoundaryLayout } from '~/components/common/ErrorBoundaryLayout'
import { Header } from '~/components/common/Header'
import { Image } from '~/components/common/Image'
import { Popup } from '~/components/common/Popup'
import { ChangePaymentMethod } from '~/components/home/ChangePaymentMethod'
import { Instructions } from '~/components/home/Instructions'
import { PaymentSuccess } from '~/components/home/PaymentSuccess'
import { PixPayment } from '~/components/home/PixPayment'
import { Product } from '~/components/home/Product'
import { TicketPayment } from '~/components/home/TicketPayment'

import backgroundImage from 'public/assets/bg.webp'

export const action = async ({ params, request }: ActionArgs) => {
  let ticket = null,
    pix = null,
    cardPayment = null,
    errorMessage = null

  invariant(params.recurrenceId, `params.recurrenceId is required`)

  const recurrenceId = Number.parseInt(params.recurrenceId)

  const { CHECKOUT_API_BASE_URL, CHECKOUT_API_SECRET } = process.env

  invariant(CHECKOUT_API_BASE_URL, `CHECKOUT_API_BASE_URL is required`)

  invariant(CHECKOUT_API_SECRET, `CHECKOUT_API_SECRET is required`)

  const recurrenceToken = generateRecurrenceToken({ secret: CHECKOUT_API_SECRET, recurrenceId })

  const api = getCheckoutApi({ baseURL: CHECKOUT_API_BASE_URL, token: recurrenceToken })

  invariant(api, `api is required`)

  const formData = await request.formData()
  const values = Object.fromEntries(formData) as { [k: string]: string }

  if (values.payment_method === 'boleto') {
    const result = await generateTicket({ api, recurrenceId })

    if (typeof result === 'string') {
      errorMessage = result
    } else {
      const ticketLink = `${CHECKOUT_API_BASE_URL}/checkout/boleto/${result?.code}`

      const charge = result?.charges?.find((charge: any) => charge?.code === result?.code) ?? null

      ticket = { code: charge?.last_transaction?.line, url: ticketLink }
    }
  } else if (values.payment_method === 'pix') {
    const result = await generatePix({ api, recurrenceId })

    if (typeof result === 'string') {
      errorMessage = result
    } else {
      const charge = result?.charges?.find((charge: any) => charge?.code === result?.code) ?? null

      pix = { code: charge?.last_transaction?.qr_code }

      // invariant(pix, `Could not generate pix`)
    }
  } else {
    const { PAGARME_API_BASE_URL, PAGARME_APP_TOKEN_ID } = process.env

    invariant(PAGARME_API_BASE_URL, `PAGARME_API_BASE_URL is required`)

    invariant(PAGARME_APP_TOKEN_ID, `PAGARME_APP_TOKEN_ID is required`)

    const pagarMeApi = getPagarMeApi({ baseURL: PAGARME_API_BASE_URL })

    invariant(pagarMeApi, `pagarMeApi is required`)

    const { name, number, expiration, cvv, price } = values

    const savedCardResult: ISaveCreditCardResponseData | string = await saveCreditCard({
      name,
      number,
      expiration,
      cvv,
      api: pagarMeApi,
      pagarMeAppId: PAGARME_APP_TOKEN_ID,
      recurrenceId
    })

    if (typeof savedCardResult === 'string') {
      errorMessage = savedCardResult
    } else {
      cardPayment = await payWithCreditCard({
        name,
        number,
        expiration,
        cvv,
        api,
        recurrenceId,
        cardToken: savedCardResult.id,
        price: `${price}`
      })

      if (cardPayment?.error) {
        errorMessage = cardPayment?.message
      }
    }
  }

  return json({ ticket, pix, cardPayment, errorMessage })
}

export const loader = async ({ params }: LoaderArgs) => {
  invariant(params.recurrenceId, `params.recurrenceId is required`)

  const recurrenceId = Number.parseInt(params.recurrenceId)

  const { CHECKOUT_API_BASE_URL, CHECKOUT_API_SECRET } = process.env

  invariant(CHECKOUT_API_BASE_URL, `CHECKOUT_API_BASE_URL is required`)

  invariant(CHECKOUT_API_SECRET, `CHECKOUT_API_SECRET is required`)

  const recurrenceToken = generateRecurrenceToken({ secret: CHECKOUT_API_SECRET, recurrenceId })

  const apiRecurrenceToken = getCheckoutApi({ baseURL: CHECKOUT_API_BASE_URL, token: recurrenceToken })

  invariant(apiRecurrenceToken, `apiRecurrenceToken is required`)

  const recurrence = await getRecurrenceData({ api: apiRecurrenceToken, recurrenceId })

  invariant(recurrence, `recurrence is required`)

  if (recurrence?.error) {
    return { error: recurrence.error }
  }

  const paymentMethodsAvailable: TPaymentMethod[] = []

  !!recurrence.plan.payment_method_boleto && paymentMethodsAvailable.push('boleto')
  !!recurrence.plan.payment_method_credit_card && paymentMethodsAvailable.push('credit_card')
  !!recurrence.plan.payment_method_pix && paymentMethodsAvailable.push('pix')

  const product: IProductProp = {
    recurrenceId,
    title: recurrence.plan.product.name,
    authorName: recurrence.plan.product.author,
    thumbnailUrl: recurrence.plan.product.image,
    paymentDate: recurrence.last_payment ?? recurrence.last_invoice,
    paymentMethod: recurrence.payment_method,
    paymentMethodsAvailable,
    price: Number.parseFloat(recurrence.plan.price)
  }

  return json({ product, recurrenceId })
}

const Index = () => {
  const { product, recurrenceId, error } = useLoaderData<typeof loader>()

  const { paymentMethod, setPaymentMethod, setPaymentMethodsAvailable } = usePaymentMethod()
  const { submitStatus, setPrice, fetcher } = useCreditCardForm()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const instructionsTitle = useMemo(() => {
    switch (paymentMethod) {
      case 'pix':
        return 'Instruções para o pagamento com PIX'
      case 'boleto':
        return 'Como realizar o pagamento do seu boleto'
      default:
        return 'Instruções de pagamento'
    }
  }, [paymentMethod])
  const popupTitle = useMemo(() => {
    if (submitStatus === 'success') {
      return 'Alterações realizadas!'
    } else if (submitStatus === 'error') {
      return 'Erro ao salvar!'
    } else {
      return ''
    }
  }, [submitStatus])
  const popupMessage = useMemo(() => {
    if (submitStatus === 'success') {
      return 'As alterações na forma de pagamento foram salvas com sucesso.'
    } else if (submitStatus === 'error') {
      return fetcher?.data?.errorMessage ?? 'Ocorreu um problema ao salvar as alterações, tente novamente mais tarde.'
    } else {
      return ''
    }
  }, [submitStatus, fetcher])

  const handleToggleModal = useCallback(() => {
    setIsModalOpen(oldIsModalOpen => !oldIsModalOpen)
  }, [])

  const handleOnCloseModal = useCallback(() => {
    handleToggleModal()
  }, [handleToggleModal])

  const handleTogglePopup = useCallback(() => {
    setIsPopupOpen(oldIsPopupOpen => !oldIsPopupOpen)
  }, [])

  useEffect(() => {
    if (paymentMethod === 'credit_card') {
      setPrice(product?.price)
    }
  }, [paymentMethod, product, setPrice])

  useEffect(() => {
    if (!paymentMethod) {
      setPaymentMethod(product?.paymentMethod)
    }

    setPaymentMethodsAvailable(product?.paymentMethodsAvailable ?? [product?.paymentMethod])
  }, [product, paymentMethod, setPaymentMethod, setPaymentMethodsAvailable])

  useEffect(() => {
    if (submitStatus !== null) {
      handleTogglePopup()
      handleOnCloseModal()
    }
  }, [submitStatus, handleTogglePopup, handleOnCloseModal])

  if (error)
    return (
      <div className="bg-black w-screen h-screen flex flex-col justify-center items-center">
        <Image
          src={logo}
          width={logoDimensions.width}
          height={logoDimensions.height}
          alt="XGrow"
          className="max-w-[200px] saturate-0"
        />
        <p className="text-default text-center max-w-[350px] break-all mt-2">{error}</p>
      </div>
    )

  return (
    <div className="w-screen min-h-screen flex">
      <div className="lg:max-w-[1060px] container mx-auto mt-20 md:mt-32 lg:mt-32 z-10">
        <Header />
        <main className="pt-5 px-7 pb-10 bg-[rgba(34, 36, 41, 0.3)] backdrop-blur-[10px] flex flex-col justify-center rounded-[10px]">
          <Product product={product} toggleModal={handleToggleModal} />
          <Divider className="mt-7 mb-5" />
          {submitStatus !== 'success' && (
            <>
              <p className="text-big-b text-center">{instructionsTitle}</p>
              <Instructions paymentMethod={paymentMethod} />
              {paymentMethod === 'pix' && <PixPayment />}
              {paymentMethod === 'boleto' && <TicketPayment />}
            </>
          )}
          <PaymentSuccess />
        </main>
        {submitStatus !== null && (
          <Popup
            type={submitStatus}
            title={popupTitle}
            message={popupMessage}
            isOpen={isPopupOpen}
            onClose={handleTogglePopup}
          />
        )}
        <ChangePaymentMethod isOpen={isModalOpen} onClose={handleOnCloseModal} recurrenceId={recurrenceId} />
      </div>
      <div className="min-h-screen w-screen absolute">
        <Image
          src={backgroundImage}
          alt="Background"
          width={bgDimensions.width}
          height={bgDimensions.height}
          layout="fill"
        />
      </div>
    </div>
  )
}

export const ErrorBoundary: ErrorBoundaryComponent = ({ error }) => {
  return <ErrorBoundaryLayout error={error} />
}

export default Index
