export const PopupSuccessIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M97.4375 49C97.4375 75.7514 75.7514 97.4375 49 97.4375C22.2486 97.4375 0.5625 75.7514 0.5625 49C0.5625 22.2486 22.2486 0.5625 49 0.5625C75.7514 0.5625 97.4375 22.2486 97.4375 49ZM43.3973 74.6473L79.3348 38.7098C80.5551 37.4895 80.5551 35.5107 79.3348 34.2904L74.9154 29.8711C73.6951 28.6506 71.7164 28.6506 70.4959 29.8711L41.1875 59.1793L27.5041 45.4959C26.2838 44.2756 24.3051 44.2756 23.0846 45.4959L18.6652 49.9152C17.4449 51.1355 17.4449 53.1143 18.6652 54.3346L38.9777 74.6471C40.1982 75.8676 42.1768 75.8676 43.3973 74.6473Z"
        fill="#93BC1E"
      />
    </svg>
  )
}
