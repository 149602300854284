export const CheckIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00049 15.5002C3.85824 15.5002 0.500488 12.1424 0.500488 8.00018C0.500488 3.85793 3.85824 0.500183 8.00049 0.500183C12.1427 0.500183 15.5005 3.85793 15.5005 8.00018C15.5005 12.1424 12.1427 15.5002 8.00049 15.5002ZM7.25274 11.0002L12.5552 5.69693L11.4947 4.63643L7.25274 8.87918L5.13099 6.75743L4.07049 7.81793L7.25274 11.0002Z"
        fill="#ADDF45"
      />
    </svg>
  )
}
