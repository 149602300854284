import { forwardRef } from 'react'

import clsx from 'clsx'

import { Spinner } from '~/components/common/Spinner'

export const SecondaryButton = forwardRef<HTMLButtonElement, TSecondaryButtonProps>(
  (
    {
      type = 'button',
      outline = false,
      disabled = false,
      small = false,
      mobile = false,
      itemsEnd = false,
      isAction = false,
      isLoading = false,
      loadingPosition = 'override',
      className,
      children,
      ...properties
    },
    reference
  ) => {
    const buttonClass = clsx(
      'btn-base',
      { 'btn-secondary': !outline && !disabled },
      { 'btn-outline': outline && !disabled },
      { 'btn-small': !mobile && small },
      { 'btn-mobile': !small && mobile },
      { 'btn-large': !small && !mobile },
      { 'btn-disabled': disabled },
      { 'items-end': itemsEnd },
      { 'items-center': !itemsEnd },
      { 'w-fill': !isAction && mobile },
      { 'opacity-75 cursor-not-allowed': isLoading },
      { '!opacity-75 !cursor-not-allowed !inline-flex !gap-[0.625rem]': isLoading && !small && !mobile },
      { '!opacity-75 !cursor-not-allowed !inline-flex !gap-1': isLoading && !small && mobile },
      className
    )
    return (
      <button type={type} className={buttonClass} ref={reference} {...properties}>
        {loadingPosition === 'left' && isLoading && <Spinner size="small" />}
        {loadingPosition === 'override' && isLoading ? <Spinner size="small" /> : children}
        {loadingPosition === 'right' && isLoading && <Spinner size="small" />}
      </button>
    )
  }
)

SecondaryButton.displayName = 'SecondaryButton'
