import { useMemo } from 'react'

import { Instruction } from './Instruction'

interface IInstructionsProps {
  paymentMethod: TPaymentMethod
}

interface IInstruction {
  id: number
  text?: string
  custom?: React.ReactNode
}

const pixInstructions: IInstruction[] = [
  { id: 0, text: 'Copie o código abaixo para pagar via PIX em qualquer aplicativo habilitado.' },
  { id: 1, text: 'Ou, abra o aplicativo do seu banco e escaneie o QR Code com o seu celular.' },
  {
    id: 2,
    custom: (
      <p>
        Você tem <strong>5 minutos</strong> para fazer o pagamento, após esse tempo você precisará solicitar um novo
        link.
      </p>
    )
  }
]

const boletoInstructions: IInstruction[] = [
  {
    id: 0,
    custom: (
      <p className="text-default">
        O pagamento no boleto bancário é <strong>somente à vista.</strong>
      </p>
    )
  },
  {
    id: 1,
    custom: (
      <p className="text-default">
        Pagamentos em boleto possuem uma <strong>taxa de R$1,99.</strong>
      </p>
    )
  },
  {
    id: 3,
    custom: (
      <p className="text-default">
        Pagamentos com boleto bancário levam até <strong>3 dias úteis</strong> para serem compensados e então terem os
        produtos liberados.
      </p>
    )
  },
  {
    id: 4,
    text: 'Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer casa lotérica até o dia do vencimento.'
  },
  {
    id: 5,
    text: 'Depois do pagamento, verifique seu e-mail para receber os dados  de acesso ao produto (verifique também a caixa de spam).'
  }
]

const creditCardInstructions: IInstruction[] = [
  {
    id: 0,
    text: 'Pagamentos por cartão de crédito são feitos de forma automática, você não precisa tomar nenhuma ação.'
  }
]

export const Instructions: React.FC<IInstructionsProps> = ({ paymentMethod }) => {
  const __instructions = useMemo(() => {
    switch (paymentMethod) {
      case 'pix':
        return pixInstructions
      case 'boleto':
        return boletoInstructions
      default:
        return creditCardInstructions
    }
  }, [paymentMethod])

  return (
    <div className="mt-3 bg-primary-90 rounded-lg py-3 px-[13.5px] flex flex-col gap-2.5">
      {__instructions?.map(instruction => (
        <Instruction key={instruction.id} text={instruction?.text}>
          {instruction.custom}
        </Instruction>
      ))}
    </div>
  )
}
