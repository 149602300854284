import { useMemo } from 'react'

import { useCreditCardForm } from '~/contexts/creditCardForm'
import { usePaymentMethod } from '~/contexts/paymentMethod'

import { PrimaryButton } from '~/components/common/Button/PrimaryButton'
import { SecondaryButton } from '~/components/common/Button/SecondaryButton'
import { SaveIcon } from '~/components/common/icons/SaveIcon'
import { Modal } from '~/components/common/Modal'
import { ModalBody } from '~/components/common/Modal/ModalBody'
import { ModalFooter } from '~/components/common/Modal/ModalFooter'
import { ModalHeader } from '~/components/common/Modal/ModalHeader'
import { Body } from './Body'

interface IChangePaymentMethodModalProps {
  isOpen: boolean
  onClose: () => void
}

export const ChangePaymentMethodModal: React.FC<IChangePaymentMethodModalProps> = ({ isOpen, onClose }) => {
  const { paymentMethod } = usePaymentMethod()
  const { isSubmitting, handleSubmitForm } = useCreditCardForm()

  const buttonText = useMemo(() => (paymentMethod === 'credit_card' ? 'Salvar e pagar' : 'Salvar'), [paymentMethod])
  const onClick = useMemo(
    () => (paymentMethod === 'credit_card' ? handleSubmitForm : onClose),
    [paymentMethod, handleSubmitForm, onClose]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Alterar forma de pagamento" />
      <ModalBody>
        <Body />
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-end gap-5">
          <SecondaryButton className="max-w-[273px]" onClick={onClose}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={isSubmitting} className="max-w-[273px]" onClick={onClick}>
            <SaveIcon />
            {buttonText}
          </PrimaryButton>
        </div>
      </ModalFooter>
    </Modal>
  )
}
