import type { ErrorBoundaryComponent } from '@remix-run/node'
import { Links, Meta, Scripts } from '@remix-run/react'

import { logoDimensions } from '~/constants/imageSizes'

import { Image } from '~/components/common/Image'

import logo from 'public/assets/logo_full-color.webp'

export const ErrorBoundaryLayout: ErrorBoundaryComponent = ({ error }) => {
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="bg-black w-screen h-screen flex flex-col justify-center items-center">
          <Image
            src={logo}
            width={logoDimensions.width}
            height={logoDimensions.height}
            alt="XGrow"
            className="max-w-[200px] saturate-0"
          />
          <p className="text-default text-center max-w-[350px] break-all">{error.message}</p>
        </div>
        <Scripts />
      </body>
    </html>
  )
}
