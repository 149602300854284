import clsx from 'clsx'
import React from 'react'

// import { mobileBreakPoint } from '@constants/windowSizes'

const dropdownMenuFlex = 'flex justify-between'
const dropdownMenuClass = 'bg-primary-70 rounded-lg mt-[6px] z-10 w-fill'
const dropdownAnimation = 'animate-toast-show transition ease-out duration-1000'
const dropdownDropUp = 'animate-drop-up transition ease-out duration-1000'

export const DropdownMenu: React.FC<IDropdownMenuProperties> = ({
  children,
  isAction,
  shouldDropUp = false,
  horizontal = false,
  mobile,
  customMargin,
  menuCenter,
  className
}) => {
  const dropdownClass = clsx(
    dropdownMenuClass,
    dropdownMenuFlex,
    { 'ml-[-180px]': isAction && !customMargin },
    { [dropdownAnimation]: !shouldDropUp },
    { [dropdownDropUp]: shouldDropUp },
    { 'flex-row': horizontal },
    { 'flex-col': !horizontal },
    customMargin && customMargin,
    { 'ml-[-80px]': !isAction && mobile && !menuCenter && !customMargin },
    className
  )
  return <ul className={dropdownClass}>{children}</ul>
}
