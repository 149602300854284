import { useEffect, useMemo, useState } from 'react'

import { useFetcher } from '@remix-run/react'

import { PrimaryButton } from '~/components/common/Button/PrimaryButton'
import { CopyIcon } from '~/components/common/icons/CopyIcon'
import { DownloadIcon } from '~/components/common/icons/DownloadIcon'
import { RefreshIcon } from '~/components/common/icons/RefreshIcon'

export const TicketPayment: React.FC = () => {
  const fetcher = useFetcher()

  const [buttonText, setButtonText] = useState('Copiar código')

  const ticket = useMemo(() => fetcher?.data?.ticket ?? null, [fetcher])
  const ticketCode = useMemo(() => ticket?.code ?? '', [ticket])
  const ticketLink = useMemo(() => ticket?.url ?? '', [ticket])
  const isGeneratingTicket = useMemo(() => fetcher.state !== 'idle', [fetcher])
  const error = useMemo(() => fetcher?.data?.errorMessage ?? null, [fetcher])

  const handleDownloadTicket = () => {
    window.open(ticketLink, '_blank', 'noopener,noreferrer')
  }

  const handleCopyTextToClipboard = () => {
    const text = ticketCode

    const textArea = document.createElement('textarea')

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed'
    textArea.style.top = '0'
    textArea.style.left = '0'

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em'
    textArea.style.height = '2em'

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0'

    // Clean up any borders.
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent'

    textArea.value = text

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')

      if (successful) {
        setButtonText('Copiado')
      }
    } catch (error) {
      // console.log('Oops, unable to copy')
    }

    document.body.removeChild(textArea)
  }

  useEffect(() => {
    if (buttonText === 'Copiado') {
      const buttonTextTimeout = setTimeout(() => {
        setButtonText('Copiar código')
      }, 2000)

      return () => clearTimeout(buttonTextTimeout)
    }
  }, [buttonText])

  if (error) {
    return (
      <div className="mt-8">
        <p className="text-default">{error}</p>
      </div>
    )
  }

  return (
    <div className="mt-8">
      {!ticket && (
        <fetcher.Form className="flex justify-center" method="post" replace>
          <input type="hidden" name="payment_method" value="boleto" />
          <PrimaryButton type="submit" className="max-w-[319px] w-full" isLoading={isGeneratingTicket}>
            <div className="w-4 h-4">
              <RefreshIcon />
            </div>
            Gerar o boleto para o pagamento
          </PrimaryButton>
        </fetcher.Form>
      )}
      {!!ticket && (
        <>
          <div className="flex flex-col items-center gap-3">
            <p className="text-default-b text-center">Para fazer o download do seu boleto, clique no link abaixo:</p>
            <div className="w-max">
              <PrimaryButton className="py-3 px-10" onClick={handleDownloadTicket}>
                <div className="flex items-center w-4 h-4">
                  <DownloadIcon />
                </div>
                Fazer download do boleto
              </PrimaryButton>
            </div>
          </div>
          <div className="mt-8 flex flex-col gap-3">
            <p className="text-default-b">Ou, você pode copiar o código de barras:</p>
            <div className="flex sm:flex-col gap-2.5">
              <input
                type="text"
                value={ticketCode}
                readOnly
                className="p-3.5 flex-1 h-12 rounded-lg bg-primary-90 border-[1px] border-secondary-90 cursor-pointer"
                role="button"
                onClick={handleCopyTextToClipboard}
              />
              <div>
                <PrimaryButton outline className="sm:w-full py-3 px-10" onClick={handleCopyTextToClipboard}>
                  <div className="flex items-center w-4 h-4">
                    <CopyIcon />
                  </div>
                  {buttonText}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
