import React from 'react'

import { Image } from '~/components/common/Image'

import CloseSvg from 'public/assets/icons/close-drawer.svg'

export const DrawerCloseButton: React.FC<IDrawerCloseButtonProps> = ({ onClose }) => {
  return (
    <button className="flex" onClick={onClose}>
      <div className="w-9 h-9">
        <Image src={CloseSvg} alt="Fechar drawer" />
      </div>
    </button>
  )
}
