import clsx from 'clsx'
import React from 'react'

import { SpinnerIcon } from '~/components/common/icons/SpinnerIcon'

import { handleDimension } from '~/utils/spinner'

export const Spinner: React.FC<ISpinnerProperties> = ({ color = '#FFFFFF', size = 'medium' }) => {
  const { dimensionClass } = handleDimension(size)

  const spinnerClass = clsx(dimensionClass)

  return (
    <div className="h-max ">
      <div className="w-max animate-spin">
        {/* @ts-expect-error className is deprecated in svg element, needs a deeper analysis to find a better solution */}
        <SpinnerIcon fill={color} className={spinnerClass} />
      </div>
    </div>
  )
}
