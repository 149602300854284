export const CheckOutlineIcon: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99996 17.3334C4.39746 17.3334 0.666626 13.6025 0.666626 9.00002C0.666626 4.39752 4.39746 0.666687 8.99996 0.666687C13.6025 0.666687 17.3333 4.39752 17.3333 9.00002C17.3333 13.6025 13.6025 17.3334 8.99996 17.3334ZM8.99996 15.6667C10.7681 15.6667 12.4638 14.9643 13.714 13.7141C14.9642 12.4638 15.6666 10.7681 15.6666 9.00002C15.6666 7.23191 14.9642 5.53622 13.714 4.28598C12.4638 3.03573 10.7681 2.33335 8.99996 2.33335C7.23185 2.33335 5.53616 3.03573 4.28591 4.28598C3.03567 5.53622 2.33329 7.23191 2.33329 9.00002C2.33329 10.7681 3.03567 12.4638 4.28591 13.7141C5.53616 14.9643 7.23185 15.6667 8.99996 15.6667ZM8.16913 12.3334L4.63329 8.79752L5.81163 7.61919L8.16913 9.97669L12.8825 5.26252L14.0616 6.44085L8.16913 12.3334Z"
        fill="#ADDF45"
      />
    </svg>
  )
}
