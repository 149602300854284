import { logoDimensions } from '~/constants/imageSizes'

import logo from 'public/assets/logo_full-color.webp'

import { Image } from '~/components/common/Image'

export const Header: React.FC = () => {
  return (
    <header className="px-7 flex items-center flex-col">
      <div className="max-w-[212px] relative">
        <Image src={logo} alt="XGrow" width={logoDimensions.width} height={logoDimensions.height} />
      </div>
      <div className="mt-7">
        <h4 className="h4-b text-center">Renovação da assinatura</h4>
      </div>
      <div className="mt-3">
        <p className="text-default text-center">
          Leia abaixo as instruções para realizar o pagamento da sua assinatura. Atente-se aos detalhes:
        </p>
      </div>
    </header>
  )
}
