const small = 4
const medium = 8
const large = 12
const extraLarge = 20

export enum SPINNER_SIZE {
  SMALL = small,
  MEDIUM = medium,
  LARGE = large,
  EXTRA_LARGE = extraLarge
}
