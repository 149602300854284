export const PopupErrorIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49 0.5625C22.2422 0.5625 0.5625 22.2422 0.5625 49C0.5625 75.7578 22.2422 97.4375 49 97.4375C75.7578 97.4375 97.4375 75.7578 97.4375 49C97.4375 22.2422 75.7578 0.5625 49 0.5625ZM72.75 61.7148C73.668 62.6328 73.668 64.1172 72.75 65.0352L65.0156 72.75C64.0977 73.668 62.6133 73.668 61.6953 72.75L49 59.9375L36.2852 72.75C35.3672 73.668 33.8828 73.668 32.9648 72.75L25.25 65.0156C24.332 64.0977 24.332 62.6133 25.25 61.6953L38.0625 49L25.25 36.2852C24.332 35.3672 24.332 33.8828 25.25 32.9648L32.9844 25.2305C33.9023 24.3125 35.3867 24.3125 36.3047 25.2305L49 38.0625L61.7148 25.25C62.6328 24.332 64.1172 24.332 65.0352 25.25L72.7695 32.9844C73.6875 33.9023 73.6875 35.3867 72.7695 36.3047L59.9375 49L72.75 61.7148Z"
        fill="#EB5757"
      />
    </svg>
  )
}
