export const DownloadIcon: React.FC = () => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.34375 11.3438H9.65625V10H0.34375V11.3438ZM9.65625 4H7V0H3V4H0.34375L5 8.65625L9.65625 4Z"
        fill="white"
      />
    </svg>
  )
}
