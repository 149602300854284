import { useMemo } from 'react'

import { mobileBreakPoint } from '~/constants/windowSizes'

import CurrencyFormat from 'react-currency-format'
import { customDatePtBR } from '~/utils/date'

import { useCreditCardForm } from '~/contexts/creditCardForm'

import { useWindowSize } from '~/hooks/useWindowSize'

import { PrimaryButton } from '~/components/common/Button/PrimaryButton'
import { RefreshIcon } from '~/components/common/icons/RefreshIcon'
import { ProductImage } from '~/components/home/Product/ProductImage'

interface IProductProps {
  product: IProductProp
  toggleModal: () => void
}

const purchaseDateMask = "dd/MM/yy 'às' HH:mm"
/* 18/02/22 às 15:45h */

export const Product: React.FC<IProductProps> = ({ product, toggleModal }) => {
  const { width: windowWidth = 0 } = useWindowSize()
  const { submitStatus } = useCreditCardForm()

  const purchaseDateFormatted = useMemo(
    () => (product?.paymentDate ? customDatePtBR(new Date(product.paymentDate), purchaseDateMask) : null),
    [product]
  )

  const paymentMethod = useMemo(() => {
    switch (product.paymentMethod) {
      case 'pix':
        return 'PIX'
      case 'boleto':
        return 'Boleto'
      case 'credit_card':
        return 'Cartão de crédito'
      default:
        return 'Boleto'
    }
  }, [product])

  return (
    <>
      <div className="container flex flex-col md:flex-row lg:flex-row xl:flex-row gap-4 justify-center">
        <div className="flex-col md:max-w-[259px] lg:max-w-[259px] w-full">
          <ProductImage src={product.thumbnailUrl} title={product.title} />
          {submitStatus !== 'success' && product.paymentMethod !== 'credit_card' && (
            <div className="mt-1.5">
              <PrimaryButton
                mobile={windowWidth < mobileBreakPoint}
                small={windowWidth > mobileBreakPoint}
                onClick={toggleModal}
              >
                <div className="w-4 h-4">
                  <RefreshIcon />
                </div>
                Alterar forma de pagamento
              </PrimaryButton>
            </div>
          )}
        </div>
        <div className="flex flex-col sm:gap-5 justify-between">
          <div>
            <p className="text-big-b">{product.title}</p>
            <p className="text-small text-secondary-70">
              {product?.authorName && (
                <>
                  Por <span className="text-small-b">{product?.authorName}</span>
                </>
              )}
            </p>
          </div>
          <div className="bg-primary-90 p-3 rounded-lg">
            <p className="text-small-b text-primary-50">DETALHES DO PRODUTO</p>
            <div className="mt-2 flex gap-6">
              <div className="flex flex-col gap-1">
                <p className="text-small">
                  <strong>Número do pedido: </strong>
                  {product.recurrenceId}
                </p>
                <p className="text-small">
                  <strong>Data da compra: </strong>
                  {purchaseDateFormatted}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-small">
                  <strong>Forma de pagamento: </strong>
                  {paymentMethod}
                </p>
                <p className="text-small">
                  <strong>Valor: </strong>
                  <CurrencyFormat
                    displayType="text"
                    prefix="R$ "
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={product.price}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
