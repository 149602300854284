import { forwardRef } from 'react'

export const SpinnerIcon: React.FC = forwardRef<SVGSVGElement, TSpinnerIcon>(
  ({ fill = '#FFFFFF', ...properties }, reference) => {
    return (
      <svg ref={reference} viewBox="0 0 84 84" xmlns="http://www.w3.org/2000/svg" {...properties}>
        <path
          d="M71.1668 42.0002C71.1668 55.9793 61.3324 67.6615 48.2047 70.5055C44.8312 71.2364 42.0002 73.9651 42.0002 77.4168V77.4168C42.0002 80.8686 44.8148 83.7161 48.2284 83.2045C68.286 80.1982 83.6668 62.8954 83.6668 42.0002C83.6668 18.9883 65.012 0.333496 42.0002 0.333496C21.105 0.333496 3.80214 15.7144 0.795857 35.7719C0.284211 39.1856 3.13172 42.0002 6.5835 42.0002V42.0002C10.0353 42.0002 12.764 39.1691 13.4948 35.7956C16.3389 22.6679 28.021 12.8335 42.0002 12.8335C58.1085 12.8335 71.1668 25.8919 71.1668 42.0002Z"
          fill={fill}
        />
      </svg>
    )
  }
)

SpinnerIcon.displayName = 'SpinnerIcon'
