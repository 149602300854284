import { ChangePaymentMethodDrawer } from '~/components/home/ChangePaymentMethod/Drawer'
import { ChangePaymentMethodModal } from '~/components/home/ChangePaymentMethod/Modal'

interface IChangePaymentMethod {
  isOpen: boolean
  onClose: () => void
  recurrenceId: number
}

export const ChangePaymentMethod: React.FC<IChangePaymentMethod> = ({ isOpen, onClose, recurrenceId }) => {
  return (
    <>
      <ChangePaymentMethodModal isOpen={isOpen} onClose={onClose} />
      <ChangePaymentMethodDrawer isOpen={isOpen} onClose={onClose} />
    </>
  )
}
