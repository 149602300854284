import { SPINNER_SIZE } from '~/constants/spinner'

export const handleDimension = (size: TSpinnerSize): IHandleDimension => {
  let dimension = 0
  let dimensionClass = 'w-0'

  switch (size) {
    case 'small': {
      dimension = SPINNER_SIZE.SMALL
      dimensionClass = 'w-4 h-4'

      break
    }
    case 'medium': {
      dimension = SPINNER_SIZE.MEDIUM
      dimensionClass = 'w-8 h-8'

      break
    }
    case 'large': {
      dimension = SPINNER_SIZE.LARGE
      dimensionClass = 'w-12 h-12'

      break
    }
    case 'extra-large': {
      dimension = SPINNER_SIZE.EXTRA_LARGE
      dimensionClass = 'w-20 h-20'

      break
    }
    default: {
      dimension = size
      dimensionClass = `w-${size} h-${size}`
    }
  }

  return { dimension, dimensionClass }
}
