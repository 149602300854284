import { Image } from '~/components/common/Image'

interface IProductImageProps {
  src: string
  title: string
}

const productImageWrapperClass =
  'md:max-w-[259px] lg:max-w-[259px] h-[134px] w-full relative bg-primary-100 rounded-[0.25rem] overflow-hidden pointer-events-none select-none'

export const ProductImage: React.FC<IProductImageProps> = props => {
  return (
    <div className={productImageWrapperClass} aria-roledescription="product-image">
      <Image src={props.src} alt={props.title} layout="fill" className="!object-contain" />
    </div>
  )
}
