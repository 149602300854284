export const SaveIcon: React.FC = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6.25H2V2.08333H12V6.25ZM9 16.6667C8.20435 16.6667 7.44129 16.3374 6.87868 15.7514C6.31607 15.1653 6 14.3705 6 13.5417C6 12.7129 6.31607 11.918 6.87868 11.332C7.44129 10.7459 8.20435 10.4167 9 10.4167C9.79565 10.4167 10.5587 10.7459 11.1213 11.332C11.6839 11.918 12 12.7129 12 13.5417C12 14.3705 11.6839 15.1653 11.1213 15.7514C10.5587 16.3374 9.79565 16.6667 9 16.6667ZM14 0H2C1.46957 0 0.960859 0.219493 0.585786 0.610194C0.210714 1.00089 0 1.5308 0 2.08333V16.6667C0 17.2192 0.210714 17.7491 0.585786 18.1398C0.960859 18.5305 1.46957 18.75 2 18.75H16C16.5304 18.75 17.0391 18.5305 17.4142 18.1398C17.7893 17.7491 18 17.2192 18 16.6667V4.16667L14 0Z"
        fill="white"
      />
    </svg>
  )
}
