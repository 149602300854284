import { useCreditCardForm } from '~/contexts/creditCardForm'

import { CheckOutlineIcon } from '~/components/common/icons/CheckOutlineIcon'

export const PaymentSuccess: React.FC = () => {
  const { submitStatus } = useCreditCardForm()

  if (submitStatus !== 'success') return <></>

  return (
    <div className="mt-5 flex gap-2 justify-center items-center rounded-lg bg-action/10 p-1.5">
      <div className="w-4 h-4">
        <CheckOutlineIcon />
      </div>
      <p className="text-default text-action">Pagamento Realizado</p>
    </div>
  )
}
