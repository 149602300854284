import { useCallback, useState } from 'react'

import { ModalCloseButton } from './ModalCloseButton'

export const Modal: React.FC<IModalProps> = ({
  children,
  isOpen,
  closeButton = true,
  backdropClose = true,
  onClose,
  isLoading = false
}) => {
  const [isMouseOverModal, setIsMouseOverModal] = useState(false)

  const handleMouseEnter = useCallback(() => {
    setIsMouseOverModal(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsMouseOverModal(false)
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  if (!isOpen) return <></>

  return (
    <div
      className="sm:hidden fixed top-0 left-0 min-h-screen w-screen overflow-hidden bg-black/70 flex justify-center items-center z-[900]"
      tabIndex={0}
      role="button"
      onClick={!isMouseOverModal && backdropClose ? onClose : undefined}
      onKeyDown={handleKeyDown}
    >
      <div
        className={`${
          !isLoading && 'bg-primary-80'
        } relative  max-w-[1096px] w-[80%] py-10 px-[53px] rounded-[7px] cursor-default animate-modal-show`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {closeButton && !isLoading && <ModalCloseButton onClose={onClose} />}
        {children}
      </div>
    </div>
  )
}
