import { logoDimensions } from '~/constants/imageSizes'

import { Image } from '~/components/common/Image'

import logo from 'public/assets/logo_full-color.webp'

export const LogoFallback: React.FC<ILogoFallbackProps> = ({ alt, ...props }) => {
  return (
    <Image
      src={logo}
      layout="fill"
      className="!object-contain mix-blend-luminosity opacity-10 select-none pointer-events-none"
      alt={alt ?? 'XGrow Fallback'}
      width={logoDimensions.width}
      height={logoDimensions.height}
      {...props}
    />
  )
}
