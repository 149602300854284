import { Divider } from '../Divider'

export const DrawerHeader: React.FC<IDrawerHeaderProps> = ({ children, title }) => {
  return (
    <header className="flex flex-col gap-2">
      {title && <h3 className="text-big-b">{title}</h3>}
      <div>{children}</div>
      <Divider />
    </header>
  )
}
