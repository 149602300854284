import { useCallback, useEffect, useRef } from 'react'

import { PrimaryButton } from '~/components/common/Button/PrimaryButton'
import { DropdownItem } from './Item'
import { DropdownMenu } from './Menu'

// type TDropDownEvent = (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => void

type TDropdownRoundedProperties = {
  isToggled: boolean
  setIsToggled: React.Dispatch<React.SetStateAction<boolean>>
  secondary?: boolean
  outline?: boolean
  disabled?: boolean
  small?: boolean
  mobile?: boolean
  dropDownOptions: IDropdownOptions[]
  itemsEnd?: boolean
  isAction?: boolean
  menuCenter?: boolean
  customMargin?: string
} & React.HTMLAttributes<HTMLDivElement>

/**
 * @see README.md Components Library for documentation
 *  */
export const DropDown: React.FC<TDropdownRoundedProperties> = ({
  isToggled,
  setIsToggled,
  children,
  mobile,
  small,
  secondary,
  outline,
  disabled,
  dropDownOptions,
  itemsEnd,
  isAction,
  menuCenter,
  customMargin,
  className
}) => {
  const thisDropdown = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (thisDropdown.current && !thisDropdown.current.contains(event.target as Node)) {
        setIsToggled(false)
      }
    },
    [setIsToggled]
  )

  const handleToggle = useCallback(() => {
    setIsToggled(oldIsToggled => !oldIsToggled)
  }, [setIsToggled])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  return (
    <div ref={thisDropdown} aria-roledescription="dropdown-action-reference" className="w-fill relative">
      <PrimaryButton
        onClick={handleToggle}
        secondary={secondary}
        outline={outline}
        mobile={mobile}
        small={small}
        disabled={disabled}
        itemsEnd={itemsEnd}
        className={className}
        isAction={isAction}
      >
        {children}
      </PrimaryButton>
      <DropdownMenu
        isAction={isAction}
        mobile={mobile}
        menuCenter={menuCenter}
        className={isToggled ? 'absolute' : 'hidden'}
        customMargin={customMargin}
      >
        {dropDownOptions.map(({ content, svgIcon, onClick }, index) => (
          <button onClick={onClick} value={content} key={`dropdown-option-${content}-${index}`}>
            <DropdownItem svgIcon={svgIcon} index={index} itemsLength={dropDownOptions.length}>
              {content}
            </DropdownItem>
          </button>
        ))}
      </DropdownMenu>
    </div>
  )
}
