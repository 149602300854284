export const RefreshIcon: React.FC = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 5.15625C11.0625 6.01042 11.3438 6.95833 11.3438 8C11.3438 9.45833 10.8125 10.7188 9.75 11.7812C8.70833 12.8229 7.45833 13.3438 6 13.3438V15.3438L3.34375 12.6562L6 10V12C7.10417 12 8.04167 11.6146 8.8125 10.8438C9.60417 10.0521 10 9.10417 10 8C10 7.375 9.84375 6.75 9.53125 6.125L10.5 5.15625ZM6 4C4.89583 4 3.94792 4.39583 3.15625 5.1875C2.38542 5.95833 2 6.89583 2 8C2 8.66667 2.15625 9.29167 2.46875 9.875L1.5 10.8438C0.9375 9.98958 0.65625 9.04167 0.65625 8C0.65625 6.54167 1.17708 5.29167 2.21875 4.25C3.28125 3.1875 4.54167 2.65625 6 2.65625V0.65625L8.65625 3.34375L6 6V4Z"
        fill="white"
      />
    </svg>
  )
}
