import { useCallback, useState } from 'react'

import { PrimaryButton } from './Button/PrimaryButton'
import { PopupErrorIcon } from './icons/PopupErrorIcon'
import { PopupSuccessIcon } from './icons/PopupSuccessIcon'

interface IPopupProps {
  isOpen: boolean
  onClose: () => void
  backdropClose?: boolean
  type: 'success' | 'error'
  title?: string
  message?: string
}

export const Popup: React.FC<IPopupProps> = ({
  isOpen,
  onClose,
  backdropClose = true,
  type = 'success',
  title,
  message
}) => {
  const [isMouseOverModal, setIsMouseOverModal] = useState(false)

  const handleMouseEnter = useCallback(() => {
    setIsMouseOverModal(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsMouseOverModal(false)
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  if (!isOpen) return <></>

  return (
    <div
      className="sm:hidden fixed top-0 left-0 min-h-screen w-screen overflow-hidden bg-black/70 flex justify-center items-center z-[900]"
      tabIndex={0}
      role="button"
      onClick={!isMouseOverModal && backdropClose ? onClose : undefined}
      onKeyDown={handleKeyDown}
    >
      <div
        className="flex flex-col items-center bg-primary-80 relative  max-w-[536px] w-[80%] py-10 px-[53px] rounded-[7px] cursor-default animate-modal-show"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="w-24 h-24">{type === 'success' ? <PopupSuccessIcon /> : <PopupErrorIcon />}</div>
        <div className="py-8 flex flex-col gap-2">
          <p className="text-big-b text-center">{title}</p>
          <p className="text-default text-center">{message}</p>
        </div>
        <div className="max-w-[138px] w-full">
          <PrimaryButton onClick={onClose}>Ok</PrimaryButton>
        </div>
      </div>
    </div>
  )
}
