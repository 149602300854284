import { useCreditCardForm } from '~/contexts/creditCardForm'

import { Input } from '~/components/common/Input'

export const CardForm: React.FC = () => {
  const {
    fetcher,
    name,
    number,
    expiration,
    cvv,
    price,
    creditCardFormRef,
    handleNameChange,
    handleNumberChange,
    handleExpirationChange,
    handleCVVChange
  } = useCreditCardForm()

  return (
    <fetcher.Form ref={creditCardFormRef} className="mt-5">
      <input type="hidden" name="payment_method" value="credit_card" required />
      <input type="hidden" name="name" value={name} required />
      <input type="hidden" name="number" value={number} required />
      <input type="hidden" name="expiration" value={expiration} required />
      <input type="hidden" name="cvv" value={cvv} required />
      <input type="hidden" name="price" value={price} required />

      <p className="text-default-b">Dados do cartão</p>

      <div className="mt-3 flex sm:flex-col gap-4">
        <Input
          type="text"
          title="Nome do titular"
          placeholder="Insira o nome do titular do cartão..."
          minLength={5}
          maxLength={40}
          value={name}
          onChange={handleNameChange}
        />
        <Input
          type="text"
          title="Número do cartão"
          placeholder="Insira o número do cartão..."
          value={number}
          mask="9999 9999 9999 9999"
          maskChar=" "
          required
          onChange={handleNumberChange}
        />
      </div>
      <div className="md:mt-5 lg:mt-5 flex sm:flex-col gap-4">
        <Input
          type="text"
          title="Validade"
          placeholder="MM/AA"
          value={expiration}
          mask="99/99"
          maskChar="•"
          required
          onChange={handleExpirationChange}
        />
        <Input
          type="text"
          title="CVV"
          placeholder="CVV"
          value={cvv}
          mask="9999"
          maskChar=" "
          required
          onChange={handleCVVChange}
        />
      </div>
    </fetcher.Form>
  )
}
