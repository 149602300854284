import { useEffect, useMemo, useState } from 'react'

import { useFetcher } from '@remix-run/react'

import { useTimer } from 'react-timer-hook'

import { QRCodeSVG } from 'qrcode.react'

import { useCreditCardForm } from '~/contexts/creditCardForm'

import { PrimaryButton } from '~/components/common/Button/PrimaryButton'
import { ClockIcon } from '~/components/common/icons/ClockIcon'
import { CopyIcon } from '~/components/common/icons/CopyIcon'
import { RefreshIcon } from '~/components/common/icons/RefreshIcon'

const date = new Date()
date.setMinutes(date.getMinutes() + 5)
const expiryTimestamp = date

export const PixPayment: React.FC = () => {
  const fetcher = useFetcher()
  const { submitStatus } = useCreditCardForm()
  const { minutes, seconds, start, isRunning, restart } = useTimer({ expiryTimestamp, autoStart: false })

  const [buttonText, setButtonText] = useState('Copiar código')

  const pix = useMemo(() => fetcher?.data?.pix ?? null, [fetcher])
  const pixCode = useMemo(() => pix?.code ?? '', [pix])
  const isGeneratingPix = useMemo(() => fetcher.state !== 'idle', [fetcher])
  const timeToExpire = useMemo(
    () => `${minutes}`.padStart(2, '0') + ':' + `${seconds}`.padStart(2, '0'),
    [minutes, seconds]
  )
  const error = useMemo(() => fetcher?.data?.errorMessage ?? null, [fetcher])

  const handleCopyTextToClipboard = () => {
    if ((minutes === 0 && seconds === 0) || submitStatus === 'success') return null

    const text = pixCode

    const textArea = document.createElement('textarea')

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed'
    textArea.style.top = '0'
    textArea.style.left = '0'

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em'
    textArea.style.height = '2em'

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0'

    // Clean up any borders.
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent'

    textArea.value = text

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')

      if (successful) {
        setButtonText('Copiado')
      }
    } catch (error) {
      // console.log('Oops, unable to copy')
    }

    document.body.removeChild(textArea)
  }

  useEffect(() => {
    if (buttonText === 'Copiado') {
      const buttonTextTimeout = setTimeout(() => {
        setButtonText('Copiar código')
      }, 2000)

      return () => clearTimeout(buttonTextTimeout)
    }
  }, [buttonText])


  useEffect(() => {
    restart(expiryTimestamp)
    if (!!pix) start()
  }, [pix])

  if (error) {
    return (
      <div className="mt-8">
        <p className="text-default">{error}</p>
      </div>
    )
  }


  return (
    <div className="mt-8">
      {!pix && (
        <fetcher.Form className="flex justify-center" method="post" replace>
          <input type="hidden" name="payment_method" value="pix" />
          <PrimaryButton type="submit" className="max-w-[319px] w-full" isLoading={isGeneratingPix}>
            <div className="w-4 h-4">
              <RefreshIcon />
            </div>
            Gerar o código PIX para o pagamento
          </PrimaryButton>
        </fetcher.Form>
      )}
      {!!pix && (
        <>
          <p className="text-default-b">Código Pix</p>
          <div className="mt-3 flex sm:flex-col gap-2.5">
            <input
              type="text"
              value={pixCode}
              readOnly
              className="p-3.5 flex-1 h-12 rounded-lg bg-primary-90 border-[1px] border-secondary-90 cursor-pointer"
              role="button"
              onClick={handleCopyTextToClipboard}
            />
            <div>
              <PrimaryButton className="sm:w-full flex items-center py-3 px-10" onClick={handleCopyTextToClipboard}>
                <div className="w-4 h-4">
                  <CopyIcon />
                </div>
                {buttonText}
              </PrimaryButton>
            </div>
          </div>
          {submitStatus !== 'success' && (
            <>
              <div className="mt-5 rounded-lg bg-secondary-100 flex justify-center p-1.5">
                <div className="flex items-center gap-2 select-none pointer-events-none">
                  <ClockIcon />
                  <p className="text-default-b text-secondary-60">{timeToExpire}</p>
                </div>
              </div>
              <div className="mt-8 flex flex-col items-center gap-5">
                <p className="text-default-b text-center">Ou, escaneie o QR Code</p>
                <div className="p-2.5 bg-white rounded-lg w-max">
                  <QRCodeSVG value={pixCode} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
