export const ClockIcon: React.FC = () => {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6813 4.97331L13.8921 3.76248L15.0705 4.94081L13.8596 6.15165C15.0561 7.64929 15.6337 9.54821 15.4739 11.4584C15.314 13.3686 14.4288 15.1451 13 16.4231C11.5713 17.701 9.70745 18.3834 7.7913 18.33C5.87515 18.2767 4.05215 17.4917 2.6967 16.1363C1.34125 14.7808 0.55625 12.9578 0.502907 11.0417C0.449565 9.12551 1.13193 7.26166 2.40987 5.83291C3.68781 4.40415 5.46432 3.51894 7.37453 3.35908C9.28475 3.19922 11.1837 3.77684 12.6813 4.97331ZM7.99964 16.6666C8.76569 16.6666 9.52423 16.5158 10.232 16.2226C10.9397 15.9295 11.5828 15.4998 12.1244 14.9581C12.6661 14.4164 13.0958 13.7734 13.3889 13.0656C13.6821 12.3579 13.833 11.5994 13.833 10.8333C13.833 10.0673 13.6821 9.30873 13.3889 8.60099C13.0958 7.89326 12.6661 7.2502 12.1244 6.70852C11.5828 6.16685 10.9397 5.73717 10.232 5.44402C9.52423 5.15086 8.76569 4.99998 7.99964 4.99998C6.45255 4.99998 4.96881 5.61456 3.87485 6.70852C2.78089 7.80249 2.16631 9.28622 2.16631 10.8333C2.16631 12.3804 2.78089 13.8641 3.87485 14.9581C4.96881 16.0521 6.45255 16.6666 7.99964 16.6666ZM7.16631 6.66665H8.83298V11.6666H7.16631V6.66665ZM4.66631 0.833313H11.333V2.49998H4.66631V0.833313Z"
        fill="#ADDF45"
      />
    </svg>
  )
}
