import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { mobileBreakPoint, tabletBreakPoint } from '~/constants/windowSizes'

import { usePaymentMethod } from '~/contexts/paymentMethod'

import { useWindowSize } from '~/hooks/useWindowSize'

import { DropDown } from '~/components/common/DropDown'
import { Image } from '~/components/common/Image'
import { CardForm } from './CardForm'

import clsx from 'clsx'
import FilterCaret from 'public/assets/icons/filter-caret.svg'

export const Body: React.FC = () => {
  const { width } = useWindowSize()
  const { paymentMethod, setPaymentMethod, paymentMethodsAvailable } = usePaymentMethod()

  const isTablet = useMemo(() => (width ?? 0) <= tabletBreakPoint, [width])
  const isMobile = useMemo(() => (width ?? 0) <= mobileBreakPoint, [width])

  const [isOpen, setIsOpen] = useState(false)

  const dropDownClass = clsx('sm:mb-4 w-fill p-2', isMobile && '!h-max !mb-0')

  const dropDownRef = useRef<HTMLDivElement>(null)

  const handleSelectOption = useCallback(
    (option: TPaymentMethod) => {
      setPaymentMethod(option)

      setIsOpen(oldIsOpen => !oldIsOpen)
    },
    [setPaymentMethod, setIsOpen]
  )

  const options: IDropdownOptions[] = useMemo(() => {
    const __options = []

    paymentMethodsAvailable.includes('boleto') &&
      __options.push({ content: 'Boleto', onClick: () => handleSelectOption('boleto') })
    paymentMethodsAvailable.includes('pix') &&
      __options.push({ content: 'PIX', onClick: () => handleSelectOption('pix') })
    paymentMethodsAvailable.includes('credit_card') &&
      __options.push({ content: 'Cartão de crédito', onClick: () => handleSelectOption('credit_card') })

    return __options
  }, [paymentMethodsAvailable, handleSelectOption])

  const selectedOption = useMemo(() => {
    switch (paymentMethod) {
      case 'pix':
        const pixOption = options.find(option => option.content === 'PIX')
        return pixOption?.content
      case 'boleto':
        const ticketOption = options.find(option => option.content === 'Boleto')
        return ticketOption?.content
      case 'credit_card':
        const creditCardOption = options.find(option => option.content === 'Cartão de crédito')
        return creditCardOption?.content
      default:
        return options?.[0]?.content
    }
  }, [options, paymentMethod])

  const handleClickOutside = (event: MouseEvent) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div>
      <p className="text-default-b">Forma de pagamento</p>
      <div ref={dropDownRef} className={`flex items-center ${isMobile && 'w-fill'} bg-primary-90 mt-3`}>
        <DropDown
          secondary
          mobile={isMobile}
          small={!isMobile && isTablet}
          isToggled={isOpen}
          setIsToggled={setIsOpen}
          dropDownOptions={options}
          // itemsEnd={isMobile}
          menuCenter={isMobile}
          className={dropDownClass}
        >
          <div className="flex items-center w-fill">
            <p>{selectedOption}</p>
          </div>
          <div className="w-4 h-4 rounded-xl bg-transparent flex justify-center mr-4">
            <Image src={FilterCaret} alt="caret-icon" className={isOpen ? 'animate-half-spin' : 'animate-back-spin'} />
          </div>
        </DropDown>
      </div>
      {paymentMethod === 'credit_card' && <CardForm />}
    </div>
  )
}
