import { Image } from '~/components/common/Image'

import CloseIconSvg from 'public/assets/icons/close.svg'

export const ModalCloseButton: React.FC<IModalCloseButtonProps> = ({ onClose }) => {
  return (
    <button className="absolute top-4 right-4" onClick={onClose}>
      <Image src={CloseIconSvg} alt="Fechar modal" iconSize />
    </button>
  )
}
