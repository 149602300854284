export const CopyIcon: React.FC = () => {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6562 14.0001V4.65637H4.34375V14.0001H11.6562ZM11.6562 3.34387C12.0104 3.34387 12.3229 3.47929 12.5938 3.75012C12.8646 4.00012 13 4.30221 13 4.65637V14.0001C13 14.3543 12.8646 14.6668 12.5938 14.9376C12.3229 15.2085 12.0104 15.3439 11.6562 15.3439H4.34375C3.98958 15.3439 3.67708 15.2085 3.40625 14.9376C3.13542 14.6668 3 14.3543 3 14.0001V4.65637C3 4.30221 3.13542 4.00012 3.40625 3.75012C3.67708 3.47929 3.98958 3.34387 4.34375 3.34387H11.6562ZM9.65625 0.656372V2.00012H1.65625V11.3439H0.34375V2.00012C0.34375 1.64596 0.46875 1.33346 0.71875 1.06262C0.989583 0.791789 1.30208 0.656372 1.65625 0.656372H9.65625Z"
        fill="white"
      />
    </svg>
  )
}
