import React from 'react'

import clsx from 'clsx'
import ReactInputMask from 'react-input-mask'

type TInputProps = {
  type: 'text' | 'password' | 'email' | 'tel' | 'url' | 'search'
  placeholder?: string
  title?: string
  mobile?: boolean
  small?: boolean
  value?: string
  disabled?: boolean
  mask?: string | (string | RegExp)[]
  maskChar?: string | null | undefined
} & React.InputHTMLAttributes<HTMLInputElement>

export const Input = React.forwardRef<ReactInputMask, TInputProps>(
  (
    {
      type,
      placeholder,
      title,
      mobile,
      small,
      value = '',
      disabled,
      className = '',
      mask = '',
      maskChar,
      ...properties
    },
    reference
  ) => {
    const shouldTitle = title && !small && !mobile
    const titleClass = clsx(
      'input-title',
      { 'text-action-dark': !disabled && title && value.length > 0 },
      { 'text-secondary-90': title && disabled }
    )
    const inputClass = clsx(
      'input-base',
      { 'input-squared': !disabled },
      { 'input-small': !mobile && small },
      { 'input-mobile': !small && mobile },
      { 'input-large': !small && !mobile },
      { 'pt-[1.875rem] px-[1rem] pb-[1rem]': shouldTitle },
      { 'px-[1rem]': !shouldTitle },
      { 'border-action-dark': value.length > 0 },
      { 'input-disabled': disabled },
      className
    )
    return (
      <div className="w-fill" aria-roledescription="input-container">
        {shouldTitle && <h6 className={titleClass}>{title}</h6>}
        <ReactInputMask
          ref={reference}
          mask={mask}
          maskChar={maskChar}
          type={type}
          className={inputClass}
          placeholder={placeholder}
          {...properties}
          disabled={disabled}
        />
      </div>
    )
  }
)

Input.displayName = 'Input'
