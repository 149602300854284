import clsx from 'clsx'

import { LogoFallback } from './LogoFallback'

export const Image: React.FC<IImageProps> = ({ layout = 'default', alt, className, iconSize, ...props }) => {
  if (!props?.src) return <LogoFallback alt={alt} />

  const imageClass = clsx(
    'w-full',
    { 'relative object-contain': layout === 'default' },
    { 'absolute object-cover': layout === 'fill' },
    className
  )

  if (iconSize)
    return (
      <div className="w-4 h-4">
        <img {...props} className={imageClass} alt={alt} />
      </div>
    )

  return <img {...props} className={imageClass} alt={alt} />
}
